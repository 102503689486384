import React from "react"
import classNames from "classnames"

const Columns = ({
  children,
  rowReverse,
  className,
  verticallyCentered,
  sideColumnsSize
}) => {
  const mainClasses = classNames("columns", className, {
    "is-vcentered": verticallyCentered,
    "flex-row-reverse": rowReverse
  })
  return (
    <div className={mainClasses}>
      {sideColumnsSize && <div className={`column is-${sideColumnsSize}`} />}
      {children}
      {sideColumnsSize && <div className={`column is-${sideColumnsSize}`} />}
    </div>
  )
}

export default Columns
