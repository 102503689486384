import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

const Section = ({
  children,
  colorBack,
  smallSpacing,
  zeroTop,
  zeroBottom,
  className
}) => {
  const wrapperClasses = classNames(className, {
    sctn: true,
    "sctn--small": smallSpacing,
    "color-back": colorBack,
    "mt-0": zeroTop && !colorBack,
    "mb-0": zeroBottom && !colorBack,
    "pt-0": zeroTop && colorBack,
    "pb-0": zeroBottom && colorBack
  })

  return <div className={wrapperClasses}>{children}</div>
}

export default Section

Section.propTypes = {
  children: PropTypes.node.isRequired,
  colorBack: PropTypes.bool,
  smallSpacing: PropTypes.bool,
  zeroTop: PropTypes.bool,
  zeroBottom: PropTypes.bool
}
